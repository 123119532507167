@import '../../../stylesheets/fonts';
@import '../../../stylesheets/colors';
@import '../../../stylesheets/constraints';

.wrapper-info {
  background: $background-white;
  padding: 61px $container-padding 80px;
  font-family: 'OpenSans Regular', sans-serif;
  font-size: 1rem;

  h1, h2, h3, a {
    font-family: 'ProximaNova Black', serif;
  }

  .flex-container {
    display: flex;
    align-items: flex-start;;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: auto;
  }

  .container-info {
    max-width: $container-width;
    align-items: baseline;
    width: 100%;

    .info {
      &.main {
        margin-right: 100px;
        max-width: 664px;
        flex-grow: 1;

        .info-item {
          margin-bottom: 48px;

          p {
            line-height: 1.38;
            margin-bottom: 0;
          }

          .about-project {
            h2 {
              margin-top: 48px;
            }
          }

          div {
            margin-bottom: 19px;

            img {
              width: 100%;

              &:not(:last-child) {
                padding-right: 29px;
              }
            }
          }
        }
      }

      &.additional {
        max-width: 278px;

        > :first-child {
          margin-bottom: 32px;
        }

        h2 {
          font-weight: 900;
          font-size: 1rem;
          text-transform: uppercase;
        }

        ul {
          padding: 0;

          li {
            font-weight: 600;
            color: $text-grey;
            line-height: 2;
            list-style-type: none;
          }
        }
      }
    }
  }

  section {
    max-width: $container-width;
    margin: auto auto 30px;

    .title {
      font-family: 'ProximaNova Black', serif;
      font-size: 1.5rem;
      font-weight: 900;
      line-height: 1.33;
      margin: 0 0 16px;
    }
  }
}

@media screen and (max-width: 964px){
  .wrapper-info {
    padding-top: 40px;
    padding-bottom: 50px;

    .container-info {
      .info.main .info-item {
        margin-bottom: 30px;
      }

      .info.additional {
        max-width: 100%;

        .info-item {
          margin-bottom: 30px;

          h2 {
            text-transform: capitalize;
            font-size: 1.5rem;
          }

         li {
           display: inline;
            line-height: 1.33;
            font-weight: 400;
          }

          li:not(:last-child):after {
            content: ", ";
            margin-left: -2px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .wrapper-info {
    padding-bottom: 30px;

    h2 {
      font-size: 1.5rem;
    }

  }
}

@media screen and (max-width: 768px) {
  .wrapper-info {
    padding-bottom: 20px;

    .info .main {
      margin-right: 0;
    }

    h2, .container-info .info.additional .info-item h2 {
      font-size: 1.25rem;
    }
  }
}
