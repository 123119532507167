@import '../../stylesheets/fonts';
@import '../../stylesheets/constraints';

main.error {
  margin-top: 0 !important;
  display: flex;
  align-items: center;

  .content {
    padding: 0 $container-padding;

    h1 {
      font-size: 18vw !important;
      margin: 0;
    }

    .title-description {
      position: absolute;
      font-family: 'ProximaNova-Extrabld', serif;
      top: 70%;
      right: 10vw;
      width: 40%;
      font-size: 3vw;
      text-align: left;
      margin: 0;
    }
  }
}

@media screen and (max-width: 860px) {
  main.error {
    min-height: 50vh !important;

    .content {
      .title-description {
        top: 45%;
        font-size: 4vw;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  main.error {
    min-height: 50vh !important;

    .content {
      .title-description {
        top: 40%;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  main.error {
    min-height: 30vh !important;

    .content {
      .title-description {
        top: 35%;
        font-size: 5vw;
      }
    }
  }
}
