@import './fonts';
@import './colors';
@import './constraints';

body {
  margin: 0;
  padding: 0;
  overflow-y: scroll;

  .application {
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    main {
      flex: 1;
      padding-top: 64px;
      padding-bottom: 50px;
      min-height: 80vh;

      h1 {
        font-family: 'ProximaNova Black', serif;
        font-size: 32px;
        font-weight: 900;
        line-height: 0.75;
        color: $text-black;
        text-transform: uppercase;
        margin-bottom: 32px;
        margin-top: 0;
      }

      h2 {
        @extend h1;
      }

      h3 {
        font-family: 'ProximaNova Black', serif;
        font-size: 24px;
        font-weight: 900;
        line-height: 0.75;
        color: $text-white;
        text-transform: uppercase;
      }

      .annotation {
        font-family: 'OpenSans Regular', serif;
        font-size: 16px;
        line-height: 1.5;
        color: $text-black;
      }
    }
  }

  .btn {
    margin: auto;
    cursor: pointer;
    transition: background-color 500ms ease;
    border: 2px solid $background-black;
    font-family: ProximaNova-Extrabld, serif;
    font-size: 14px;
    width: 300px;
    text-transform: uppercase;
    padding: 18px 20px;
    color: $text-black;
    text-decoration: none;
    text-align: center;
    z-index: 10;

    &:focus {
      outline: none;
    }

    &:hover {
      background: $background-black;
      color: $text-white;
    }
  }

  .join-our-team-link {
    z-index: 0;
  }
}

img {
  max-width: 100%;
}

iframe {
  position: relative;
  width: 100%;
  max-width: 100%;
}

table {
  width: 100%;
  max-width: 100%;
  margin: 1rem 0;
  background-color: transparent;
  border-collapse: collapse;

  thead th {
    vertical-align: bottom;
  }

  td, th {
    padding: .75rem;
    vertical-align: top;
    border: 1px solid #dee2e6;
  }
}

.hide-overflow {
  overflow-y: hidden;
}

.hidden {
  display: none !important;
}

@media screen and (max-width: 992px) {
  body .application main {
    padding-top: 40px;

    .btn {
      width: auto;
      padding: 10px 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  body .application main {
    padding-top: 32px;
  }
}
