@import '../../../stylesheets/fonts';
@import '../../../stylesheets/colors';
@import '../../../stylesheets/constraints';

.footer-hot-links {
  width: 100%;
  max-width: 350px;
  box-sizing: border-box;
  padding-right: $container-padding;

  h2, h3 {
    text-transform: uppercase;
  }

  h3 {
    font-size: 16px;
    opacity: 0.6;
    margin-bottom: 20px;
  }

  a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.6);
    white-space: nowrap;
    font-family: 'OpenSans Regular', serif;
    position: relative;
  }

  a:before {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $text-white;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  a:hover:before {
    visibility: visible;
    width: 100%;
  }

  .footer-hot-links-block {
    display: flex;
    justify-content: space-between;
    padding-top: 27px;
    border-top: 2px solid rgba(255, 255, 255, 0.6);
    font-size: 0.75rem;

    ul {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      li {
        margin-right: 0px;
        list-style-type: none;
        margin-bottom: 5px;
        max-width: 75px;
        line-height: 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  footer .footer-wrapper .footer-top .footer-hot-links {
    min-width: 100%;
  }
}
