@import '../../stylesheets/fonts';
@import '../../stylesheets/colors';
@import '../../stylesheets/constraints';

footer {
  display: flex;
  justify-content: center;
  background-color: $background-grey;
  min-width: 300px;

  .footer-wrapper {
    padding: 40px 0;
    width: $container-width;
    margin: 0 $container-padding;

    h1, h2, h3 {
      font-family: 'ProximaNova-Extrabld', serif;
      color: $text-white;
    }

    p {
      font-family: 'OpenSans Regular', serif;
      font-size: 12px;
      font-weight: 400;
      color: $text-white;
      opacity: 0.6;
      margin: 21px 0 16px;
    }

    p.copyright {
      opacity: 0.4;
      margin-bottom: 0;
    }

    .footer-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-right: -$container-padding;
    }
  }
}

@media screen and (max-width: 992px) {
  footer .footer-wrapper {
    padding: 22px 0;

    h2 {
      margin: 10px 0;
    }
  }
}
