@import '../../stylesheets/fonts';
@import '../../stylesheets/colors';
@import '../../stylesheets/constraints';

.developer {
  width: 100%;
  padding: 0 $container-padding;
  box-sizing: border-box;

  .developer-wrapper {
    max-width: $container-width;
    width: 100%;
    margin: 0 auto;

    h2.contributor-projects {
      font-size: 16px;
      font-weight: 400;
    }

    .developer-section {
      display: flex;
      margin-bottom: 32px;

      .developer-avatar-wrapper {
        flex-direction: column;
        margin-right: 64px;

        img {
          display: block;
          width: 300px;
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
        }
      }

      .developer-info-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h2.developer-fullname {
          word-break: break-word;
          margin-bottom: 8px;
          font-size: 48px;
          line-height: 0.9;
        }

        h3.developer-position {
          color: $text-black;
          font-family: 'OpenSans Regular';
          text-transform: uppercase;
          margin-top: 0;
          font-size: 18px;
          font-weight: 300;
        }

        .social-networks-links {
          display: flex;
          justify-content: center;

          img {
            width: 32px;
            height: 32px;
            margin-right: 6px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .developer {
    .developer-wrapper {
      .developer-section {
        flex-direction: column;

        .developer-avatar-wrapper {
          margin-bottom: 32px;
          margin-right: 0;

          img {
            width: 400px;
            margin: 0 auto;
          }
        }

        .developer-info-wrapper {
          margin: 0 auto;
        }
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .developer .developer-wrapper .developer-section .developer-avatar-wrapper img {
    min-width: auto;
    width: 100%;
  }
}
