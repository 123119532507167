@import '../../../stylesheets/fonts';
@import '../../../stylesheets/colors';

.section-wrapper {
  margin-bottom: 50px;

  .section-title {
    margin-bottom: 50px;
  }

  .developers-wrapper {
    max-width: 940px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 0.2fr));
    margin-bottom: 30px;
  }
}
