@import '../../stylesheets/fonts';
@import '../../stylesheets/colors';

main#flow {
  padding-top: 0;

  .pdf {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    .react-pdf__message {
      font-family: 'ProximaNova Black', serif;
      font-size: 32px;
      text-transform: uppercase;
      text-align: center;
    }
  }

  .download-pdf {
    padding-top: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 60%;
    margin: auto;

    .black-line {
      margin: auto auto 50px;
      width: 100%;
      height: 5px;
      background-color: $background-black;
      text-align: center;
    }

    .main {
      font-family: 'ProximaNova Black', serif;
      font-size: 100px;
      font-weight: 900;
      line-height: 0.75;
      margin: auto auto 30px;
      text-align: center;
      color: $text-black;
    }

    .annotation {
      margin: auto auto 30px;
      font-family: 'OpenSans Regular', serif;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.13;
      letter-spacing: normal;
      text-align: center;
      color: $text-black;
    }
  }
}

@media screen and (max-width: 1440px) {
  main#flow {
    .download-pdf {
      width: 80%;
    }
  }
}

@media screen and (max-width: 1280px) {
  main#flow {
    .download-pdf {
      .main {
        font-size: 48px;
      }
    }
  }
}

@media screen and (max-width: 980px){
  main#flow {
    .pdf {
      display: none;
    }

    .download-pdf {
      .main {
        font-size: 48px;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  main#flow {
    .download-pdf {
      .main {
        font-size: 36px;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  main#flow {
    .download-pdf {
      .main {
        font-size: 30px;
      }
    }
  }
}

