@import '../../../../stylesheets/fonts';
@import '../../../../stylesheets/colors';

.project {
  height: 318px;
  width: 295px;
  margin-bottom: calc((940px - 295px*3)/2);
  z-index: 0;

  a.project-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;
    width: 100%;
    height: 100%;
    padding: 25px;
    color: $text-black;
    font-family: 'OpenSans Regular', serif;
    box-sizing: border-box;
    z-index: 1;

    .project-content-wrapper {
      width: 100%;
      height: 100%;
      max-height: 170px;
      margin-bottom: 20px;

      .project-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 80px;

        .project-name {
          max-width: 200px;

          h3 {
            font-family: 'ProximaNova Black', serif;
            font-size: 24px;
            font-weight: 900;
            text-transform: uppercase;
            color: $text-black;
            font-style: normal;
            font-stretch: normal;
            line-height: 0.8;
            letter-spacing: normal;
            margin: 0;
          }
        }

        .project-technology-icon img {
          width: 36px;
          height: 36px;
          background: $background-white;
          filter: grayscale(1);
        }
      }

      .project-description {
        line-height: 1.38;
        margin-right: 9px;
      }
    }

    .project-git-info {
      display: flex;
      margin-bottom: 22px;

      .star, .fork, .open_issues {
        border-right: 1px solid white;
        background: rgba($background-white, 0.25);
        text-align: left;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }

        i, svg {
          margin-right: 5px;
        }
      }

      .star {
        i {
          color: $star;
        }
      }

      .fork {
        i {
          color: $fork;
        }
      }

      .open_issues {
        i {
          color: $open_issues;
        }
      }
    }

    .view-project {
      text-transform: uppercase;
      font-family: 'ProximaNova Black', serif;
      color: $text-black;
      text-align: right;
      line-height: 1;
    }
  }

  .background {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: .3s;
    z-index: -1;
    position: relative;
    top: -320px;
    right: 2px;
    border: 2px solid $background-black;
  }

  a.project-wrapper:hover + .background {
    transform: scale(1.05);
    z-index: -1;
  }
}

@media screen and (max-width: 711px) {
  .project {
    width: 100%;
  }
}
