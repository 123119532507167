@import '../../../stylesheets/fonts';
@import '../../../stylesheets/colors';

.statistic-wrapper {
  display: flex;
  border: 4px solid $text-black;

  .statistic-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    &:after {
      position: absolute;
      content:"";
      background: $text-black;
      bottom: 10%;
      right: 0;
      height: 80%;
      width: 2px;
    }

    &:last-child:after {
      display: none;
    }

    .header, .value {
      font-family: 'ProximaNova-Extrabld', serif;
    }

    .header {
      text-transform: uppercase;
      font-size: 16px;
    }

    .value {
      font-size: 42px;
    }
  }
}

@media screen and (max-width: 500px) {
  .statistic-wrapper {
    .statistic-item {
      .header {
        font-size: 14px !important;
      }

      .value {
        font-size: 36px !important;
      }
    }
  }
}
