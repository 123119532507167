@import '../../../../stylesheets/fonts';
@import '../../../../stylesheets/colors';

.item {
  width: 90%;
  border: 4px solid #4a4a4a;
  position: relative;
  margin-bottom: 8px;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  .content-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;

    img {
      display: block;
      width: 100%;
      height: 100%;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      background-color: $background-white;
    }

    .text {
      font-family: 'ProximaNova Black', serif;
      font-size: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
      text-transform: uppercase;
    }
  }
}

.item:hover .overlay {
  opacity: 1;
}
