@import '../../../stylesheets/fonts';
@import '../../../stylesheets/colors';
@import '../../../stylesheets/constraints';

.footer-projects {
  width: 100%;
  max-width: 280px;
  box-sizing: border-box;

  h2 {
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.6);
    font-family: 'OpenSans Regular', serif;
    position: relative;
  }

  a:before {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $text-white;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  a:hover:before {
    visibility: visible;
    width: 100%;
  }

  .footer-projects-block {
    padding-top: 27px;
    border-top: 2px solid rgba(255, 255, 255, 0.6);
    font-size: 0.9rem;

    ul {
      padding: 0;

      li {
        margin-right: 0px;
        list-style-type: none;
        margin-bottom: 5px;
        line-height: 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 1017px) {
  .footer-projects {
    margin-right: $container-padding;
  }
}

@media screen and (max-width: 800px) {
  footer .footer-wrapper .footer-top .footer-projects {
    min-width: 100%;
    padding-right: $container-padding;
  }
}
