@import '../../stylesheets/fonts';
@import '../../stylesheets/colors';

main#committee {
  .greeting {
    .greeting-wrapper {
      height: calc(100vh - 50px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      span {
        font-family: 'ProximaNova Black', serif;
        font-size: 200px;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.75;
        letter-spacing: normal;
        color: $text-black;
        text-transform: uppercase;
        text-align: left;
        margin-bottom: 70px;
      }
    }
  }

  .people {
    background-image: linear-gradient(to bottom, #f2f2f2, #eaeaea);
    display: flex;
    justify-content: center;
    padding-top: 100px;

    .committee-wrapper {
      width: 60%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .get-in-touch {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 60%;
    margin: 0 auto;

    .black-line {
      width: 100%;
      margin: 100px auto 50px;
      height: 5px;
      background-color: $background-black;
      text-align: center;
    }

    .main {
      font-family: 'ProximaNova Black', serif;
      font-size: 100px;
      font-weight: 900;
      line-height: 0.75;
      margin: auto auto 30px;
      text-align: center;
      color: $text-black;
    }

    .annotation {
      margin: auto auto 30px;
      font-family: 'OpenSans Regular', serif;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.13;
      letter-spacing: normal;
      text-align: center;
      color: $text-black;
    }
  }
}


@media screen and (max-width: 1280px) {
  main#committee {
    .greeting {
      .greeting-wrapper {
        span {
          font-size: 120px;
        }
      }
    }

    .people {
      padding: 100px 50px 0;

      .committee-wrapper {
        width: auto;
      }
    }

    .get-in-touch {
      width: 80%;

      .main {
        font-size: 48px;
      }
    }
  }
}

@media screen and (max-width: 860px) {
  main#committee {
    .greeting {
      .greeting-wrapper {
        span {
          font-size: 90px;
        }
      }
    }

    .get-in-touch {
      .main {
        font-size: 48px;
      }

      .annotation {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  main#committee {
    .greeting {
      .greeting-wrapper {
        span {
          font-size: 42px;
        }
      }
    }

    .get-in-touch {
      .main {
        font-size: 36px;
      }

      .annotation {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  main#committee {
    .greeting {
      .greeting-wrapper {
        span {
          font-size: 36px;
        }
      }
    }

    .get-in-touch {
      .main {
        font-size: 36px;
        line-height: 1;
      }

      .annotation {
        font-size: 14px;
      }
    }
  }
}


