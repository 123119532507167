@font-face {
  font-family: 'ProximaNova Black';
  src: url('/app/assets/fonts/ProximaNova-Black/ProximaNova-Black.eot');
  src: url('/app/assets/fonts/ProximaNova-Black/ProximaNova-Black.eot?.iefix') format('embedded-opentype'),
  url('/app/assets/fonts/ProximaNova-Black/ProximaNova-Black.woff') format('woff'),
  url('/app/assets/fonts/ProximaNova-Black/ProximaNova-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Extrabld';
  src: url('/app/assets/fonts/ProximaNova-ExtraBold/ProximaNova-Extrabld.eot');
  src: url('/app/assets/fonts/ProximaNova-ExtraBold/ProximaNova-Extrabld.eot?.iefix') format('embedded-opentype'),
  url('/app/assets/fonts/ProximaNova-ExtraBold/ProximaNova-Extrabld.woff') format('woff'),
  url('/app/assets/fonts/ProximaNova-ExtraBold/ProximaNova-Extrabld.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans Regular';
  src: url('/app/assets/fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
