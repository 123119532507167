@import '../../stylesheets/fonts';
@import '../../stylesheets/colors';
@import '../../stylesheets/constraints';

header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: $background-black;
  color: $text-white;
  padding: 0 $container-padding;

  .logo {
    display: flex;
    align-items: center;
    height: 55px;

    a {
      display: flex;
      text-decoration: none;
      color: inherit;
      align-items: flex-end;

      img, span {
        height: 19px;
      }

      img {
        width: 94px;
        margin-right: 10px;
      }

      span {
        font-family: 'OpenSans Regular', serif;
        font-size: 14px;
        padding-top: 2px;
        box-sizing: border-box;
      }
    }
  }

  .nav {
    display: none;
    box-sizing: border-box;
    align-items:center;

    .burger{
      padding: 10px;
      width: 35px;
      height: 30px;
      box-sizing: border-box;

      .burger-wrapper {
        height: 20px;
        width: 20px;
        position: relative;

        span {
          position: absolute;
          width: 16px;
          height: 2px;
          background: $background-white;
          transition: 0.2s ease-in-out;
        }

        span:nth-child(1) {
          top: 0;
        }

        span:nth-child(2), span:nth-child(3) {
          top: 6px;
        }

        span:nth-child(4) {
          top: 12px;
          left: 4px;
          width: 12px;
        }
      }
    }

    .active {
      span {
        transition: 0.2s ease-in-out;
      }

      span:nth-child(1), span:nth-child(4) {
        display: none;
      }

      span:nth-child(2) {
        transform: rotate(45deg);
      }

      span:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }

  .links {
    display: flex;
    align-items: center;
    height: 55px;
    justify-content: space-between;

    div {
      margin-right: 44px;

      a {
        color: $text-white;
        text-transform: uppercase;
        font-family: 'ProximaNova-Extrabld', serif;
        font-size: 14px;
        font-weight: 900;
        line-height: 1.29;
        letter-spacing: 0.7px;
        text-decoration: none;
      }

      a.submit-project-link {
        border: 2px solid $background-white;
        padding: 5px;
        transition: background-color 500ms ease;

        &:hover {
          background-color: $background-white;
          color: $text-black
        }

        &:hover:before {
          visibility: hidden;
          width: 100%;
        }
      }
    }

    div:last-child {
      margin-right: 0;
    }

    a {
      text-decoration: none;
      position: relative;
    }

    a:before {
      content: '';
      position: absolute;
      width: 0;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: $text-white;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    }

    a:hover:before {
      visibility: visible;
      width: 100%;
    }
  }

  .burger-menu {
    position: absolute;
    top: 55px;
    left: 0px;
    width: 100vw;
    height: calc(100vh - 55px);
    background-color: $background-black;
    padding-top: 30px;
    justify-content: center;
    z-index: 1;
    overflow: hidden;

    .links {
      display: flex;
      flex-direction: column;
      padding-top: 40px;
      box-sizing: border-box;
      align-items: center;

      div:first-child {
        margin-top: 0;
      }

      div {
        margin-right: 0;
        margin-top: 25px;
      }

      .join-us {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  header .nav {
    display: flex;
  }

  header .links {
    display: none;
  }
}
