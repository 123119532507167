@import '../../../stylesheets/fonts';
@import '../../../stylesheets/colors';

.committee-item {
  margin-bottom: 100px;
  width: 50%;
  display: flex;
  justify-content: center;

  .committee-item-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 70%;

    img {
      width: 100%;
      margin-bottom: 30px;
      filter: grayscale(1);
    }

    .committee-fullname {
      display: block;
      width: 100%;
      margin-bottom: 10px;
      text-align: center;
      font-family: 'ProximaNova Black', serif;
      font-size: 32px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.22;
      letter-spacing: normal;
      color: $text-black;
      text-transform: uppercase;
      text-decoration: underline;
    }

    .position {
      font-family: 'OpenSans Regular', serif;
      font-size: 22px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.36;
      letter-spacing: normal;
      text-align: center;
      color: $text-black;
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: 360px) {
  .committee-item {
    width: 100%;

    .committee-item-wrapper {
      width: 100%;

      .committee-fullname {
        font-size: 22px;
      }

      .position {
        font-size: 18px;
      }
    }
  }
}

@media screen and (min-width: 361px) and (max-width: 580px) {
  .committee-item {
    width: 100%;

    .committee-item-wrapper {
      width: 100%;

      .committee-fullname {
        font-size: 22px;
      }

      .position {
        font-size: 18px;
      }
    }
  }
}

@media screen and (min-width: 581px) and (max-width: 940px) {
  .committee-item {
    width: 100%;

    .committee-item-wrapper {
      width: 100%;

      .committee-fullname {
        font-size: 28px;
      }

      .position {
        font-size: 24px;
      }
    }
  }
}

@media screen and (min-width: 941px) and (max-width: 1280px) {
  .committee-item {
    .committee-item-wrapper {
      .committee-fullname {
        font-size: 18px;
      }

      .position {
        font-size: 14px;
      }
    }
  }
}

@media screen and (min-width: 1281px) and (max-width: 1480px) {
  .committee-item {
    .committee-item-wrapper {
      .committee-fullname {
        font-size: 28px;
      }
    }
  }
}
