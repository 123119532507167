@import '../../stylesheets/fonts';
@import '../../stylesheets/colors';
@import '../../stylesheets/constraints';

main.community {
  width: 100%;
  padding: 0 $container-padding;
  box-sizing: border-box;

  .community-wrapper {
    max-width: $container-width;
    width: 100%;
    margin: 0 auto;

    .header-wrapper {
      display: flex;
      justify-content: space-between;
      align-self: center;

      a.btn {
        border: none;
        background-color: $background-black;
        color: $text-white;
        padding: 5px 20px;
        margin: 0;
        width: fit-content;
        height: fit-content;
      }
    }

    .statistic-wrapper {
      margin-bottom: 80px;
    }
  }
}

@media screen and (max-width: 960px) {
  main.community {
    .statistic-wrapper {
      .statistic-item {
        .header {
          font-size: 14px !important;
        }

        .value {
          font-size: 28px !important;
        }
      }
    }

    .section-title {
      font-size: 22px !important;
    }
  }
}

@media screen and (max-width: 810px) {
  main.community {
    .header-wrapper {
      flex-direction: column;
      margin-bottom: 32px;
    }

    .developers-wrapper {
      grid-template-columns: repeat(auto-fit, minmax(100px, 0.2fr));
    }
  }
}

@media screen and (max-width: 580px) {
  main.community {
    .developers-wrapper {
      grid-template-columns: repeat(auto-fit, minmax(85px, 0.2fr));
    }
  }
}

@media screen and (max-width: 560px) {
  main.community {
    .statistic-wrapper {
      .statistic-item {
        .header {
          font-size: 12px !important;
        }

        .value {
          font-size: 22px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  main.community {
    .statistic-wrapper {
      flex-direction: column;

      .statistic-item {
        width: 100% !important;
        padding-top: 10px;

        &:after {
          bottom: 10%;
          right: unset;
          height: 2px;
          width: 80%
        }

        &:last-child {
          padding-bottom: 10px;
        }

        .header {
          font-size: 16px !important;
        }

        .value {
          font-size: 28px !important;
        }
      }
    }
  }
}
