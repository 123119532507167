@import '../../../stylesheets/fonts';
@import '../../../stylesheets/colors';
@import '../../../stylesheets/constraints';

.footer-contacts {
  width: 100%;
  max-width: 280px;
  box-sizing: border-box;

  h2, h3 {
    text-transform: uppercase;
  }

  h3 {
    font-size: 16px;
    opacity: 0.6;
    margin-bottom: 20px;
    font-weight: 400;
  }

  a {
    text-decoration: none;
    color: $text-white;
  }

  a:hover {
    text-decoration: underline;
  }

  .footer-contacts-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 27px;
    border-top: 2px solid rgba(255, 255, 255, 0.6);

    p {
      margin: 0 0 13px;
    }
  }
}

@media screen and (max-width: 992px) {
  .footer-contacts .footer-contacts-block {
    padding-top: 10px;
  }
}

@media (max-width: 800px) {
  footer .footer-wrapper .footer-top .footer-contacts {
    min-width: 100%;
    padding-right: $container-padding;

    .contact-info {
      width: 50%;
    }
  }
}

@media (max-width: 600px) {
  footer .footer-wrapper .footer-top .footer-contacts .footer-contacts-block {
    justify-content: space-between;
  }
}
