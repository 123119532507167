@import '../../../stylesheets/fonts';
@import '../../../stylesheets/colors';

.projects-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.projects-section::after {
  content: '';
  width: 295px;
}
