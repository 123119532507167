@import 'colors';

@mixin blue-gradient-background {
  background: rgb(0,75,178);
  background: linear-gradient(0deg, rgba(0,197,242,1) 0%, rgba(0,75,178,1) 100%);
}

@mixin green-gradient-background {
  background: rgb(67,220,198);
  background: linear-gradient(0deg, rgba(67,220,198,1) 0%, rgba(35,188,74,1) 100%);
}

@mixin red-gradient-background {
  background: rgb(255,157,45);
  background: linear-gradient(0deg, rgba(255,157,45,1) 0%, rgba(191,22,105,1) 100%);
}
