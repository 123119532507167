@import '../../stylesheets/fonts';
@import '../../stylesheets/colors';
@import '../../stylesheets/constraints';

main {
  .form-wrapper {
    width: 50%;
    max-width: 600px;
    margin: auto;

    p.contact-us-subtitle {
      margin: 0 0 20px;
      font-family: 'OpenSans Regular', serif;
      font-size: 16px;
      line-height: 1.5;
      color: $text-black;
    }

    form {
      text-align: center;
      margin-bottom: 20px;

      .form-element {
        margin-bottom: 20px;
        width: calc(100% - 40px);
        padding: 18px 20px;
        border: 2px solid $background-black;
        font-family: ProximaNova-Extrabld, serif;
        font-size: 14px;

        &:focus {
          outline: none;
        }
      }

      .checkbox-form-element {
        @extend .form-element;
        border: none;
        padding: 0;
        text-align: left;

        input[type='checkbox'] {
          margin-right: 10px;
        }

        .checkbox-button {
          cursor: pointer;

          .checkbox-button__input {
            box-sizing: border-box;
            font-size: 1rem;
            line-height: 1.5;
            padding: 11px 23px;
            border: 1px solid $text-black;
            border-radius: 0;
            outline: 0;
            background-color: transparent;
            opacity: 0;
            position: absolute;
          }

          .checkbox-button__control {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 12px;
            vertical-align: middle;
            background-color: inherit;
            border: 2px solid $text-black;
            margin-bottom: 3px;
            transform: scale(0.75);
          }

          .checkbox-button__input:checked+.checkbox-button__control:after {
            content: "";
            display: block;
            position: absolute;
            top: 5px;
            left: 5px;
            width: 10px;
            height: 10px;
            background-color: $text-black;
          }

          .checkbox-button__label {
            font-family: 'OpenSans Regular', serif;

            a {
              color: $text-black;
              text-decoration: underline;

              &:hover, &:visited, &:focus {
                @extend a;
                outline: none;
              }
            }
          }
        }
      }

      textarea {
        resize: none;
        width: 100%;
        height: 200px;

        &:focus {
          outline: none;
        }
      }

      input[type='submit'] {
        cursor: pointer;
        transition: background-color 500ms ease;
        border: 2px solid $background-black;
        font-family: ProximaNova-Extrabld, serif;
        font-size: 14px;
        width: 250px;
        text-transform: uppercase;
        padding: 18px 20px;
        background-color: $background-white;

        &:focus {
          outline: none;
        }

        &:hover {
          background: $background-black;
          color: $text-white;
        }

        &:disabled {
          border-color: graytext;
          pointer-events: none;

        }
      }

      input:invalid.displayErrors, textarea:invalid.displayErrors {
        border-color: $high-important-action;
      }

      .error-message {
        margin-bottom: 20px;
        font-family: ProximaNova-Extrabld, serif;
        font-size: 14px;
        text-transform: uppercase;
        color: $high-important-action;
        text-align: left;
      }

      .error-message.hide {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  main {
    .form-wrapper {
      width: 80%;

      h1.contact-us-title {
        font-size: 20px;
      }

      p.contact-us-subtitle {
        font-size: 14px;
      }

      .form-element, input[type='submit'] {
        padding: 12px 14px !important;
      }

      .form-element {
        width: calc(100% - 28px) !important;
      }
    }
  }
}

@media screen and (min-width: 361px) and (max-width: 540px) {
  main {
    .form-wrapper {
      width: 80%;

      h1.contact-us-title {
        font-size: 20px;
      }

      p.contact-us-subtitle {
        font-size: 14px;
      }
    }
  }
}

@media screen and (min-width: 541px) and (max-width: 860px) {
  main {
    .form-wrapper {
      width: 80%;
    }

    h1.contact-us-title {
      font-size: 24px;
    }
  }
}

@media screen and (min-width: 861px) and (max-width: 1280px) {
  main {
    .form-wrapper {
      width: 50%;
    }
  }
}
