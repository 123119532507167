@import '../../stylesheets/fonts';
@import '../../stylesheets/constraints';

.our-mission {
  padding-bottom: 48px;

  .content-wrapper {
    width: 1180px;
    margin: auto;
    font-family: 'OpenSans Regular', serif;
    display: flex;
    flex-flow: column nowrap;

    .btn {
      margin-top: 30px;
    }
  }

  .title-wrapper {
    display: flex;
    justify-content: center;

    .our-mission-title {
      font-size: 100px;
      margin-top: 100px;
      margin-bottom: 100px;
      text-align: center;
      position: relative;
      display: inline-block;

      .rocket {
        position: absolute;
        top: -65%;
        right: -15%;
      }
    }
  }

  p {
    text-align: justify;
  }


}


@media screen and (max-width: 1280px) {
  .our-mission {
    .content-wrapper {
      width: 80%;

      .our-mission-title .rocket {
        width: 50%;
        top: -31%;
        right: -10%;
      }
    }
  }
}

@media screen and (max-width: 860px) {
  .our-mission .content-wrapper .our-mission-title {
    margin-bottom: 50px;
    font-size: 70px;
  }
}



@media screen and (max-width: 700px) {
  .our-mission .content-wrapper .our-mission-title {
    font-size: 40px;
  }
}

@media screen and (max-width: 320px) {
  .our-mission .content-wrapper .our-mission-title {
    font-size: 34px;
  }
}
