@import '../../../stylesheets/fonts';
@import '../../../stylesheets/colors';

.greeting-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  .greeting {
    font-family: 'ProximaNova-Extrabld', serif;
    margin-bottom: 50px;
  }

  .sub-greeting {
    font-family: 'Open Sans', serif;
    font-size: 24px;
    margin: 0 0 90px;
  }

  a {
    text-decoration: none;
    color: $text-black;
    text-align: center;
    cursor: pointer;
    transition: background-color 500ms ease;
    border: 2px solid $background-black;
    font-family: ProximaNova-Extrabld, serif;
    font-size: 14px;
    text-transform: uppercase;
    padding: 18px 50px;

    &:focus {
      outline: none;
    }

    &:hover {
      background: $background-black;
      color: $text-white;
    }
  }
}

@media screen and (max-width: 360px) {
  .greeting-wrapper {
    h1, p {
      text-align: center;
      width: 80%;
    }
  }
}

@media screen and (min-width: 361px) and (max-width: 540px) {
  .greeting-wrapper {
    h1, p {
      text-align: center;
      width: 80%;
    }
  }
}

@media screen and (min-width: 541px) and (max-width: 860px) {
  .greeting-wrapper {
    h1, p {
      text-align: center;
      width: 80%;
    }
  }
}

@media screen and (min-width: 861px) and (max-width: 1280px) {
  .greeting-wrapper {
    h1, p {
      text-align: center;
      width: 80%;
    }
  }
}

