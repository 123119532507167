@import '../../../../stylesheets/fonts';
@import '../../../../stylesheets/colors';

.contributor-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  img {
    height: 100px;
    width: 100px;
    margin-right: 20px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  .info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    a.profile-link {
      &, &:hover, &:visited {
        color: $text-black;
        text-decoration: none;
      }

      .fullname {
        font-family: ProximaNova-Extrabld, serif;
        font-size: 18px;
        margin: 0 0 5px;
      }
    }

    .position {
      font-family: 'OpenSans Regular', serif;
      font-size: 16px;
      margin: 0 0 5px;
    }

    .social-networks-links a img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
}
