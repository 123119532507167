@import '../../../stylesheets/fonts';
@import '../../../stylesheets/colors';
@import '../../../stylesheets/constraints';

.without-decoration {
  text-decoration: none;
}

.overlay {
  background-color: $background-black;
  background-size: cover;
}
.wrapper-header {
  padding: 51px $container-padding 40px;
  background-color: rgba($background-black, 0.6);
  margin: auto;
  font-size: 1rem;

  h1, a {
    font-family: 'ProximaNova Black', serif;
  }

  p, span {
    font-family: 'OpenSans Regular', sans-serif;
  }

  .flex-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .flex-container.space-between {
    color: $text-white;
    max-width: $container-width;
    justify-content: space-between;
    margin: auto;

    .info {
      min-width: 600px;
      max-width: 658px;
      flex-grow: 1;
    }

    img {
      flex-grow: 0;
      min-width: 36px;
      height: 36px;
      align-self: flex-start;
      margin-right: 15px;
      background-color: $background-white;
      box-sizing: border-box;
    }

    .title {
      font-size: 2.25rem;
      font-weight: 900;
      margin: 0;
      line-height: 38px;
    }

    .description {
      line-height: 1.38;
      margin: 24px 0 34px;
    }

    .statistic {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      border: 1px solid $background-white;
      line-height: 26px;
      color: $text-white;
      box-sizing: border-box;
      margin-right: 13px;

      .statistic-item {
        display: flex;
        flex-flow: row nowrap;
        border-right: 1px solid $background-white;
        padding: 0 10px;
        align-items: center;
      }

      .statistic-count {
        text-align: center;
        min-width: 50px;
        padding: 0 5px;
      }

      .statistic-item {
        background-color: rgba($background-white, 0.25);

        span {
          padding-left: 10px;
        }
      }

      span {
        font-weight: 600;
      }
    }

    .statistic:last-child {
      margin-right: 0;
    }
  }

  .main-link {
    border: 2px solid $background-white;
    padding: 5px;
    transition: background-color 500ms ease;
    flex-grow: 1;
    max-width: 278px;
    min-width: 200px;
    text-transform: uppercase;
    font-weight: 900;
    height: 52px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    color: $text-white;
    margin: 34px 0;

    &:hover {
      background-color: $background-white;
      color: $text-black
    }

    &:hover:before {
      visibility: hidden;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1000px){
  .wrapper-header {
    .flex-container.space-between {
      .info {
        min-width: 100%;
        max-width: 100%;

        .title {
          font-size: 1.75rem;
        }

        .project-git-info.flex-container {
          justify-content: space-between;
        }
      }
    }

    a.main-link {
      max-width: 100%;
      min-width: 100%;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 992px) {
  .wrapper-header {
    padding-top: 40px;
  }
}

@media screen and (max-width: 768px) {
  .wrapper-header {
    padding-top: 20px;
  }
}

@media screen and (max-width: 550px) {
  .wrapper-header {
    .flex-container .statistic-item {
      height: 26px;

      span {
        display: none;
      }
    }
  }
}
