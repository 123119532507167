@import '../../../stylesheets/fonts';
@import '../../../stylesheets/colors';

$border-color: #aaaaaa;

.menu {
  border: 2px solid $border-color;
  font-family: 'ProximaNova-Extrabld', serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;

  .nav, .social {
    box-sizing: border-box;

    a, span {
      color: $text-white;
      display: inline-block;
    }

    a {
      text-decoration: none;
      position: relative;
    }

    a:before {
      content: '';
      position: absolute;
      width: 0;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: $text-white;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    }

    a:hover:before {
      visibility: visible;
      width: 100%;
    }

    span {
      font-size: 16px;
      margin: 0 4px;
    }
  }
}

@media (max-width: 800px) {
  .menu {
    flex-direction: column;
  }
}
