// Backgrounds
$background-white: #ffffff;
$background-grey: rgb(19, 19, 19);
$background-black: #000000;

// Text
$text-white: #ffffff;
$text-black: #000000;
$text-grey: #4a4a4a;

// Action items
$important-action: blue;
$high-important-action: red;

// Items
$star: #F5B102;
$fork: #00AE83;
$open_issues: #4960AD;
