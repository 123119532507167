@import '../../stylesheets/fonts';
@import '../../stylesheets/colors';
@import '../../stylesheets/constraints';
@import '../../stylesheets/mixins.scss';

.projects {
  width: 100%;
  padding: 0 $container-padding;
  box-sizing: border-box;

  .projects-wrapper {
    max-width: $container-width;
    width: 100%;
    margin: 0 auto;

    .header-wrapper {
      display: flex;
      justify-content: space-between;
      align-self: center;

      a.btn {
        border: none;
        background-color: $background-black;
        color: $text-white;
        padding: 5px 20px;
        margin: 0;
        width: fit-content;
        height: fit-content;
      }
    }

    h1, .annotation {
      margin-bottom: 32px;
    }

    .annotation {
      .softserve-repository-link {
        font-family: 'ProximaNova Black', serif;
        font-size: 16px;
        font-weight: 900;
        color: $text-black;
        text-transform: uppercase;
        text-decoration: none;
        border: 2px solid $text-black;
        padding: 17px 48px 15px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        transition: 0.3s;
        white-space: nowrap;
        width: 327px;
        height: 52px;
        margin-top: 32px;

        &:hover {
          background: $background-black;
          color: $text-white;
          transition: 0.3s;
        }
      }
    }

    .featured-section {
      h2 {
        font-size: 16px;
      }

      .projects-section {
        .project {
          &:first-child {
            @include blue-gradient-background;
          }

          &:nth-child(2) {
            @include green-gradient-background;
          }

          &:nth-child(3) {
            @include red-gradient-background;
          }

          .project-wrapper {
            .project-content-wrapper *,
            .project-git-info > * ,
            .view-project {
              color: $text-white;
            }

            .project-content-wrapper {
              .project-header {
                .project-technology-icon {
                  display: none;
                }
              }
            }

            .project-git-info * {
              background: transparent;
              border: none;
            }
          }

          .background {
            display: none;
          }
        }
      }
    }

    .filters {
      form {
        display: flex;
        flex-direction: column;

        .main-elements-wrapper {
          display: flex;
          flex-direction: row;
          margin-bottom: 24px;

          .form-group {
            margin-right: 32px;

            button {
              all: unset;
              font-family: 'ProximaNova Black', serif;
              font-size: 16px;
              color: $text-black;
              text-transform: uppercase;
              display: flex;
              align-items: center;
              cursor: pointer;

              i {
                margin-left: 6px;
                padding-bottom: 8px;
                color: $text-black;
              }
            }
          }
        }

        .badges {
          display: block;
          margin-bottom: 32px;

          .selected-item {
            display: inline-block;
            margin: 0 10px 10px 0;
            padding: 4px 12px;
            border: 2px solid $text-black;
            font-family: 'OpenSans Regular', serif;
            font-size: 16px;
            color: $text-black;

            &:last-child {
              margin-right: 0;
            }

            button {
              margin-left: 10px;
            }
          }
        }
      }

      button {
        all: unset;
        cursor: pointer;
        font-family: 'OpenSans Regular', serif;
        font-size: 16px;
        color: $text-black;

        &.hidden {
          display: none;
        }
      }
    }

    .empty-results {
      text-align: center;
      text-transform: uppercase;
      font-family: 'ProximaNova Black', serif;
      font-size: 24px;
    }
  }
}

.popover {
  width: 226px;
  border-radius: 1px;
  box-shadow: 0 2px 7px 0 rgba(141, 135, 135, 0.5);
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  padding: 16px 16px 20px;

  .checkbox {
    font-family: 'OpenSans Regular', serif;
    font-size: 16px;
    line-height: 2;

    [type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }

    [type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
    }

    [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      border: 2px solid $text-black;
    }

    [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
      content: '';
      width: 8px;
      height: 8px;
      background: $text-black;
      position: absolute;
      top: 6px;
      left: 6px;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }

    [type="checkbox"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  }
}

@media screen and (max-width: 992px) {
  .projects .projects-wrapper h1, .annotation {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 810px) {
  .header-wrapper {
    flex-direction: column;
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 768px) {
  .projects .projects-wrapper h1, .annotation {
    margin-bottom: 32px;

    h1 {
      font-size: 24px !important;
    }
  }
}

@media screen and (max-width: 711px) {
  .projects .projects-wrapper {
    h1 {
      font-size: 20px;
    }

    p {
      font-size: 14px;
    }
  }

  .softserve-repository-link {
    width: 100%;
    display: block;
    box-sizing: border-box;
    text-align: center;
  }
}

@media screen and (max-width: 540px) {
  .main-elements-wrapper {
    flex-direction: column !important;

    .form-group {
      margin-bottom: 15px;
    }
  }
}
